import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-rockford-illinois.png'
import image0 from "../../images/cities/scale-model-of-frank-lloyd-wright's-laurent-house-in-rockford-illinois.png"
import image1 from "../../images/cities/scale-model-of-anderson-japanese-gardens-in-rockford-illinois.png"
import image2 from "../../images/cities/scale-model-of-midway-village-museum-in-rockford-illinois.png"
import image3 from "../../images/cities/scale-model-of-discovery-center-museum-in-rockford-illinois.png"
import image4 from "../../images/cities/scale-model-of-rockford-park-district-in-rockford-illinois.png"
import image5 from "../../images/cities/scale-model-of-burpee-museum-of-natural-history-in-rockford-illinois.png"
import image6 from "../../images/cities/scale-model-of-riverfront-museum-park-in-rockford-illinois.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Rockford'
            state='Illinois'
            image={image}
            lat='42.2711311'
            lon='-89.0939952'
            attractions={ [{"name": "Frank Lloyd Wright's Laurent House", "vicinity": "4646 Spring Brook Rd, Rockford", "types": ["point_of_interest", "establishment"], "lat": 42.299822, "lng": -89.02436499999999}, {"name": "Anderson Japanese Gardens", "vicinity": "318 Spring Creek Rd, Rockford", "types": ["park", "point_of_interest", "establishment"], "lat": 42.2898817, "lng": -89.05787939999999}, {"name": "Midway Village Museum", "vicinity": "6799 Guilford Rd, Rockford", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.2804989, "lng": -88.98463989999999}, {"name": "Discovery Center Museum", "vicinity": "711 N Main St, Rockford", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.27687419999999, "lng": -89.0891231}, {"name": "Rockford Park District", "vicinity": "401 S Main St, Rockford", "types": ["park", "point_of_interest", "establishment"], "lat": 42.268418, "lng": -89.09725200000003}, {"name": "Burpee Museum of Natural History", "vicinity": "737 N Main St, Rockford", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.277314, "lng": -89.08814899999999}, {"name": "Riverfront Museum Park", "vicinity": "711 N Main St #7, Rockford", "types": ["park", "museum", "point_of_interest", "establishment"], "lat": 42.2769363, "lng": -89.08899070000001}] }
            attractionImages={ {"Frank Lloyd Wright's Laurent House":image0,"Anderson Japanese Gardens":image1,"Midway Village Museum":image2,"Discovery Center Museum":image3,"Rockford Park District":image4,"Burpee Museum of Natural History":image5,"Riverfront Museum Park":image6,} }
       />);
  }
}